import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`How to enable dfx pull for your canister and thrill your users with a seamless development experience. If you are looking for how to use dfx pull, check out the `}<a parentName="p" {...{
        "href": "./use-dfx-pull"
      }}>{`DFX Pull Example`}</a>{`.`}</p>
    <h1 {...{
      "id": "resources"
    }}><a parentName="h1" {...{
        "href": "#resources"
      }}>{`Resources`}</a></h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://internetcomputer.org/docs/current/developer-docs/setup/pulling-canister-dependencies/"
        }}>{`DFX Pull Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./use-dfx-pull"
        }}>{`DFX Pull Example`}</a></li>
    </ul>
    <h1 {...{
      "id": "disclaimer"
    }}><a parentName="h1" {...{
        "href": "#disclaimer"
      }}>{`Disclaimer`}</a></h1>
    <p>{`There's probably a really clever way to automate all of this with Github releases, but I got bored while trying to prove it out. For this example, gone as far as setting up the build job for my auth client demo repo and publishing the wasm + candid, but ideally it would also handle the `}<inlineCode parentName="p">{`sha256`}</inlineCode>{` and `}<inlineCode parentName="p">{`dfx.json`}</inlineCode>{` updates as well.`}</p>
    <h1 {...{
      "id": "the-easy-way"
    }}><a parentName="h1" {...{
        "href": "#the-easy-way"
      }}>{`The Easy way`}</a></h1>
    <p>{`First, you need to publish your wasm somewhere. You can create a release on Github and drag your wasm out of `}<inlineCode parentName="p">{`.dfx/local`}</inlineCode>{` for it, or you can use a CI/CD pipeline to publish it to a registry. I'm using Github Actions to publish my wasm to the Github registry. I'm using this script - `}<a parentName="p" {...{
        "href": "https://github.com/krpeacock/auth-client-demo/blob/main/.github/workflows/release.yml"
      }}>{`https://github.com/krpeacock/auth-client-demo/blob/main/.github/workflows/release.yml`}</a>{`. If I push a tag with a `}<inlineCode parentName="p">{`v`}</inlineCode>{` prefix, it will publish the wasm to the Github registry and tag the release as latest.`}</p>
    <p>{`If you are using this approach, and `}<inlineCode parentName="p">{`dfx`}</inlineCode>{`, you can update `}<inlineCode parentName="p">{`dfx.json`}</inlineCode>{` in your project to make the canister pullable. An example might look like this:`}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token property"
        }}>{`"whoami"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"main"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/whoami/main.mo"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"motoko"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"pullable"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"dependencies"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"wasm_url"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"https://github.com/krpeacock/auth-client-demo/releases/latest/download/whoami.wasm"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"init_guide"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"null"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}</code></pre>
    <p>{`Since the canister doesn't require any dependencies, we can leave that array empty. The `}<inlineCode parentName="p">{`wasm_url`}</inlineCode>{` is the url to the wasm file in the Github registry. The `}<inlineCode parentName="p">{`init_guide`}</inlineCode>{` is a guide for how to initialize the canister. Since this canister doesn't require any initialization, we can set it to `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <p>{`DFX already adds the candid metadata for you automatically, so with the `}<inlineCode parentName="p">{`pullable`}</inlineCode>{` configuration in place, you can now `}<inlineCode parentName="p">{`dfx pull`}</inlineCode>{` this canister from the IC and use it in a local project.`}</p>
    <h2 {...{
      "id": "managing-dependencies"
    }}><a parentName="h2" {...{
        "href": "#managing-dependencies"
      }}>{`Managing Dependencies`}</a></h2>
    <p>{`This gets a little trickier, but is basically the same. Make sure that every dependency you want to pull is also pullable. If you do not control all the canisters in that dependency chain, you may need to create custom wasm releases with the appropriate metadata to make this possible.`}</p>
    <h1 {...{
      "id": "the-hard-way"
    }}><a parentName="h1" {...{
        "href": "#the-hard-way"
      }}>{`The Hard Way`}</a></h1>
    <p>{`If you don't want to use `}<inlineCode parentName="p">{`dfx`}</inlineCode>{`, you can still make your canister pullable. I know you sickos are out there, so let's get into it. `}</p>
    <h2 {...{
      "id": "firm-requirements"
    }}><a parentName="h2" {...{
        "href": "#firm-requirements"
      }}>{`Firm Requirements`}</a></h2>
    <p>{`Your wasm must have `}<inlineCode parentName="p">{`icp:public candid:service`}</inlineCode>{` and `}<inlineCode parentName="p">{`icp:public dfx`}</inlineCode>{` custom sections. The `}<inlineCode parentName="p">{`icp:public candid:service`}</inlineCode>{` section is the contents of your `}<inlineCode parentName="p">{`.did`}</inlineCode>{` file. The `}<inlineCode parentName="p">{`icp:public dfx`}</inlineCode>{` section is a json object with the following shape:`}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"pullable"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"wasm_url"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"https://github.com/krpeacock/auth-client-demo/releases/latest/download/whoami.wasm"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"wasm_hash"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token null keyword"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"dependencies"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"init_guide"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"null"`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`Of these, the `}<inlineCode parentName="p">{`wasm_hash`}</inlineCode>{` is optional. Everything else must be provided.`}</p>
    <h2 {...{
      "id": "how-to-insert-the-custom-sections"
    }}><a parentName="h2" {...{
        "href": "#how-to-insert-the-custom-sections"
      }}>{`How to insert the custom sections`}</a></h2>
    <p>{`I'm told you can do this in Rust somehow. I don't know how to do that, so I'm going to recommend using `}<a parentName="p" {...{
        "href": "https://github.com/dfinity/ic-wasm"
      }}>{`ic-wasm`}</a>{`. `}</p>
    <p>{`You can install ic-wasm with `}<inlineCode parentName="p">{`cargo install ic-wasm`}</inlineCode>{`. Once installed, you can use it to add the custom sections to your wasm. `}</p>
    <p>{`Let's say you hav a `}<inlineCode parentName="p">{`whoami.wasm`}</inlineCode>{` file, as well as your `}<inlineCode parentName="p">{`whoami.did`}</inlineCode>{` and `}<inlineCode parentName="p">{`whoami-meta.json`}</inlineCode>{` files. You can add the custom sections with the following commands:`}</p>
    <pre {...{
      "className": "language-bash"
    }}><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ic-wasm --output whoami.wasm whoami.wasm metadata --file whoami.did --visibility public candid:service
ic-wasm --output whoami.wasm whoami.wasm metadata --file whoami-meta.json --visibility public dfx
`}</code></pre>
    <p>{`Breaking down what's happening - you are telling `}<inlineCode parentName="p">{`ic-wasm`}</inlineCode>{` to output the result to `}<inlineCode parentName="p">{`whoami.wasm`}</inlineCode>{`, and then you are telling it to add the `}<inlineCode parentName="p">{`candid:service`}</inlineCode>{` and `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` custom sections to the wasm. The `}<inlineCode parentName="p">{`--visibility`}</inlineCode>{` flag tells it to make the custom section public. The `}<inlineCode parentName="p">{`--file`}</inlineCode>{` flag tells it where to get the contents of the custom section from.`}</p>
    <p>{`You can verify the custom sections were added with `}<inlineCode parentName="p">{`ic-wasm whoami.wasm metadata dfx`}</inlineCode>{` and `}<inlineCode parentName="p">{`ic-wasm whoami.wasm metadata candid:service`}</inlineCode>{`.`}</p>
    <p>{`Then, you need to publish the wasm as above, and `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` will be able to consume it.`}</p>
    <h3 {...{
      "id": "custom-sections-if-you-have-init-arguments"
    }}><a parentName="h3" {...{
        "href": "#custom-sections-if-you-have-init-arguments"
      }}>{`Custom Sections If You Have Init Arguments`}</a></h3>
    <p>{`If the canister you are publishing has init arguments, you should also provide a `}<inlineCode parentName="p">{`candid:args`}</inlineCode>{` custom section. This is done for you with `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` automatically, but if you are doing it manually, you can use the did file as a reference. For example, if your candid includes the following:`}</p>
    <pre><code parentName="pre" {...{}}>{`type A = nat;
service : (A, text) -> {
  ...
};
`}</code></pre>
    <p>{`Then your `}<inlineCode parentName="p">{`candid:args`}</inlineCode>{` would be `}<inlineCode parentName="p">{`(nat, text)`}</inlineCode>{`. You can add this custom section with the following command:`}</p>
    <pre {...{
      "className": "language-bash"
    }}><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ic-wasm --output whoami.wasm whoami.wasm metadata --data `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"(nat, text)"`}</span>{` --visibility public candid:args
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      